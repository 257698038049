@import url("https://use.typekit.net/tzd2gii.css");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'minion-pro', serif;
  font-size: 16px;
  letter-spacing: 1px;
  overflow-x: hidden;
  /*background-color: #A3CEF1;*/
  padding-bottom: 100px;
}

button, input {
  /*font-family: 'Futura', sans-serif;*/
  font-family: 'minion-pro', serif;
  text-transform: uppercase;
  font-size: 18px;
}

a,
a:visited {
  color: #fff;
  text-decoration: none;
}

a:hover {
   text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #fff;
}
